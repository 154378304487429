@font-face {
    font-family: 'Myriad Pro';
    src: url('../assets/fonts/MYRIADPRO-LIGHT.otf') format('opentype');
    font-weight: 100;
}
@font-face {
    font-family: 'Myriad Pro';
    src: url('../assets/fonts/MYRIADPRO-COND.otf') format('opentype');
    font-weight: 300;
}
@font-face {
    font-family: 'Myriad Pro';
    src: url('../assets/fonts/MYRIADPRO-REGULAR.otf') format('opentype');
    font-weight: 400;
}
@font-face {
    font-family: 'Myriad Pro';
    src: url('../assets/fonts/MYRIADPRO-SEMIBOLD.otf') format('opentype');
    font-weight: 600;
}
@font-face {
    font-family: 'Myriad Pro';
    src: url('../assets/fonts/MYRIADPRO-BOLD.otf') format('opentype');
    font-weight: 700;
}
